// Libs
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

// Stores
import { useAuthStore } from "./stores/auth.store";
import { useOrganisationStore } from "./stores/organisation.store";

export const isAuth = (_: RouteLocationNormalized, __: RouteLocationNormalized, next: NavigationGuardNext) => {
  const authStore = useAuthStore();
  if (authStore.isAuth) {
    next();
  } else {
    next("/login");
  }
};

export const isOrganisationSelected = async (_: RouteLocationNormalized, __: RouteLocationNormalized, next: NavigationGuardNext) => {
  const organisationStore = useOrganisationStore();
  await organisationStore.waitForOrganisationsToBeloaded();
  if (!organisationStore.selectedOrganisation) {
    next("/select-organisation");
    return;
  }
  next();
};

export const isGuest = (_: RouteLocationNormalized, __: RouteLocationNormalized, next: NavigationGuardNext) => {
  const authStore = useAuthStore();
  if (!authStore.isAuth) {
    next();
  } else {
    next("/me");
  }
};

export const logout = (_: RouteLocationNormalized, __: RouteLocationNormalized, next: NavigationGuardNext) => {
  const authStore = useAuthStore();
  authStore.logout();
  next("/login");
};