// Libs
import { createRouter, createWebHistory } from "vue-router";

// Components
import SelectOrganisationComponent from "./components/select-organisation.component.vue";
import OrganisationCreateComponent from "./components/organisation-create.component.vue";
import OrganisationComponent from "./components/organisation.component.vue";
import OrganisationSettingsComponent from "./components/organisation-settings.component.vue";
import OrganisationDangerComponent from "./components/organisation-danger.component.vue";
import SettingsComponent from "./components/settings.component.vue";
import SettingsProfileComponent from "./components/settings-profile.component.vue";
import SettingsPasswordComponent from "./components/settings-password.component.vue";
import SettingsRemoveAccountComponent from "./components/settings-remove-account.component.vue";
import LoginComponent from "./components/login.component.vue";
import RegisterComponent from "./components/register.component.vue";
import ForgotPasswordComponent from "./components/forgot-password.component.vue";
import ActivateAccountComponent from "./components/activate-account.component.vue";
import NotFoundComponent from "./components/not-found.component.vue";

// Middlewares
import * as middlewares from "./middlewares";

export const router = createRouter({
  history: createWebHistory(),
  routes: [{
    path: "/",
    beforeEnter: middlewares.isAuth,
    children: [
      {
        path: "/select-organisation",
        name: "select-organisation",
        component: SelectOrganisationComponent,
      },
      {
        path: "/organisation/create",
        name: "organisation.create",
        component: OrganisationCreateComponent,
      },
      {
        path: "/",
        beforeEnter: middlewares.isOrganisationSelected,
        children: [{
          path: "/organisation",
          name: "organisation",
          component: OrganisationComponent,
          redirect: { name: 'organisation.settings' },
          children: [
            {
              path: "/organisation/settings",
              name: "organisation.settings",
              component: OrganisationSettingsComponent,
            },
            {
              path: "/organisation/danger",
              name: "organisation.danger",
              component: OrganisationDangerComponent,
            },
  ]
        }]
      },
      {
        path: "/settings",
        name: "settings",
        component: SettingsComponent,
        redirect: `/settings/profile`,
        children: [
          {
            path: "profile",
            name: "settings.profile",
            component: SettingsProfileComponent,
          },
          {
            path: "password",
            name: "settings.password",
            component: SettingsPasswordComponent,
          },
          {
            path: "remove-account",
            name: "settings.remove-account",
            component: SettingsRemoveAccountComponent,
          },
        ],
      },
      {
        path: "/logout",
        name: "logout",
        component: { beforeRouteEnter: middlewares.logout },
      },
    ]
  },
  {
    path: "/",
    beforeEnter: middlewares.isGuest,
    children: [
      {
        path: "/login",
        name: "login",
        component: LoginComponent,
      },
      {
        path: "/register",
        name: "register",
        component: RegisterComponent,
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: ForgotPasswordComponent,
      },
    ],
  },{
    path: "/activate-account",
    name: "activate-account",
    component: ActivateAccountComponent,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: NotFoundComponent,
    props: { code: 404 },
  }]
});