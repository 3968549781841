// Libs
import { RouteLocationNormalizedLoaded } from "vue-router";

export function queryStringFromRoute(
  route: RouteLocationNormalizedLoaded,
  param: string
): string | undefined {
  const value = route.query[param];
  if (typeof value === "string") {
    return value;
  } else if (
    value instanceof Array &&
    value.length > 0 &&
    typeof value[0] === "string"
  ) {
    return value[0];
  }
}
