<template>
  <confirm-component @submit="submit" @cancel="cancel" v-model:loading="loading">
    Are you sure you want to delete organisation {{ organisation.name }}? This action cannot be undone.
  </confirm-component>
</template>
<script lang="ts" setup>
// Libs
import { inject, ref } from 'vue';

// Components
import ConfirmComponent from './confirm.component.vue';

// Stores
import { useOrganisationStore } from '../stores/organisation.store';

// DTO's
import { Organisation } from '../dto/organisation';

const dialogRef: any = inject('dialogRef');

const organisation: Organisation = dialogRef.value.data.organisation;

const organisationStore = useOrganisationStore();

const loading = ref(false);

async function submit() {
  loading.value = true;

  await organisationStore.removeOrganisation(organisation.id)
  
  await organisationStore.reloadOrganisations();
  organisationStore.clearSelectedOrganisation();

  close();
}

function cancel() {
  close();
}

function close() {
  dialogRef.value.close();
}
</script>