// Utils
import { getWeekNumberFromDate } from "./get-week-number-from-date";
import { padLeft } from "./pad-left";

export function dateFormat(date: Date | string, format: string): string {
  if (typeof date === "string") {
    date = new Date(date);
  }
  return format
    .replace("YYYY", date.getFullYear().toString())
    .replace("MM", padLeft(date.getMonth() + 1, 2, "0"))
    .replace("DD", padLeft(date.getDate(), 2, "0"))
    .replace("mm", `${date.getMonth() + 1}`)
    .replace("dd", `${date.getDate()}`)
    .replace("HH", padLeft(date.getHours(), 2, "0"))
    .replace("hh", `${date.getHours()}`)
    .replace("II", padLeft(date.getMinutes(), 2, "0"))
    .replace("ii", `${date.getMinutes()}`)
    .replace("W", `${getWeekNumberFromDate(date)}`)
    .replace("WW", `${padLeft(getWeekNumberFromDate(date), 2, "0")}`);
}
