<template>
  <DynamicDialog />
  <ConfirmDialog />
  <ConfirmPopup />
  <router-view :key="organisationStore.selectedOrganisation?.id"></router-view>
</template>
<script lang="ts" setup>
import { RouterView } from 'vue-router';
import DynamicDialog from 'primevue/dynamicdialog';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';

import { useOrganisationStore } from '../stores/organisation.store';

const organisationStore = useOrganisationStore();
</script>