// Libs
import { IReCaptchaComposition } from "vue-recaptcha-v3";

export async function getRecaptcha(
  reCaptcha: IReCaptchaComposition
): Promise<string> {
  if (process.env.ENV === "local") {
    return "";
  }
  await reCaptcha?.recaptchaLoaded();
  return (await reCaptcha?.executeRecaptcha("login")) || "";
}
