<template>
  <Fieldset class="mb-5" legend="Update Organisation" v-if="authStore.isAdmin">
    <form @submit.prevent="submit(organisationForm)">
      <organisation-form-component v-model="organisationForm" @isValid="validate" :saving="saving" />
      <div class="flex justify-content-end">
        <Button type="submit" :disabled="!isValid" :loading="saving" label="Save Organisation" />
      </div>
    </form>
  </Fieldset>
  <Fieldset class="mb-5" legend="Users">
    <DataTable data-test="organisation-users-list" :value="organisationStore.selectedOrganisation.users || []" class="mb-5" :row-class="data => data.user.id === authStore.me?.id ? ['bg-green-100'] : []">
      <Column field="user.email" header="Email"></Column>
      <Column field="user.name" header="Name">
        <template #body="{ data }">
          {{ data.user.name }}
          <i v-if="data.user.id === authStore.me?.id" class="text-400">(you)</i>
        </template>
      </Column>
      <Column field="role" header="Role"></Column>
      <Column field="active" header="Active"></Column>
      <Column v-if="authStore.isAdmin" body-class="flex justify-content-end gap-2" header-class="text-right flex justify-content-end" header="&nbsp;">
        <template #body="{ data }">
          <ellipsis-resource-menu-component :disabled="data.role === 'owner' || (data.role === 'admin' && authStore.isAdmin && !authStore.isOwner) || data.user.id === authStore.me?.id" :items="[
            ...(authStore.isOwner && data.role === 'admin' ? [{ label: 'Transfer Ownership', icon: 'fa-solid fa-crown', action: () => TransferOwnershipToUser(data.user) }] : []),
            ...(authStore.isOwner && data.role === 'admin' ? [{ label: 'Demote to user', icon: 'fa-solid fa-user', action: () => demoteUser(data.user) }] : []),
            ...(authStore.isOwner && data.role === 'user' ? [{ label: 'Promote to Admin', icon: 'fa-solid fa-user-tie', action: () => PromoteUser(data.user) }] : []),
            ...((authStore.isOwner && (data.role === 'user' || data.role === 'admin')) || (authStore.isAdmin && (data.role === 'user')) ? [{ label: 'Remove', icon: 'pi pi-trash', action: () => removeUser(data.user) }] : []),
          ]" />
        </template>
      </Column>
    </DataTable>
  </Fieldset>
  <Fieldset class="mb-5" legend="Invite" v-if="authStore.isAdmin">
    <organisation-invite-component :organisation="organisationStore.selectedOrganisation" />
  </Fieldset>
</template>
<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';

// PrimeVue
import Button from 'primevue/button';
import Fieldset from 'primevue/fieldset';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useDialog } from 'primevue/usedialog';

// Components
import OrganisationFormComponent, { OrganisationForm } from './organisation-form.component.vue';
import EllipsisResourceMenuComponent from './ellipsis-resource-menu.component.vue';
import OrganisationInviteComponent from './organisation-invite.component.vue';
import OrganisationUserDemoteComponent from './organisation-user-demote.component.vue';
import OrganisationUserPromoteComponent from './organisation-user-promote.component.vue';
import OrganisationUserRemoveComponent from './organisation-user-remove.component.vue';
import OrganisationUserTransferOwnershipComponent from './organisation-user-transfer-ownership.component.vue';

// Stores
import { useOrganisationStore } from '../stores/organisation.store';
import { useAuthStore } from '../stores/auth.store';

// DTO's
import { User } from '../dto/user';

// Utils
import { openDialog } from '../utils';

const dialog = useDialog();

const organisationStore = useOrganisationStore();
const authStore = useAuthStore();

const saving = ref(false);
const isValid = ref(false);

const organisationForm = ref<OrganisationForm>({
  name: "",
});

const organisationId = computed(() => {
  const organisationId = organisationStore.selectedOrganisation?.id;
  if(organisationId === undefined) {
    throw new Error("Organisation not found");
  }
  return organisationId;
});

onMounted(() => {
  organisationForm.value.name = organisationStore.selectedOrganisation?.name || "";
});

function validate(valid: boolean) {
  if(valid && organisationForm.value.name !== organisationStore.selectedOrganisation?.name) {
    isValid.value = true;
  } else {
    isValid.value = false;
  }
}

async function submit(organisation: OrganisationForm) {
  if(organisationStore.selectedOrganisation === undefined) {
    return;
  }

  saving.value = true;
  await organisationStore.update(organisationStore.selectedOrganisation.id, {
    name: organisation.name
  });

  organisationStore.reloadOrganisations();
  saving.value = false;
}

function TransferOwnershipToUser(user: User): void {
  openDialog({
    dialog,
    component: OrganisationUserTransferOwnershipComponent,
    header: "Transfer ownership",
    data: { user }
  });
}
function demoteUser(user: User): void {
  openDialog({
    dialog,
    component: OrganisationUserDemoteComponent,
    header: "Demote user",
    data: { user }
  });
}
function PromoteUser(user: User): void {
  openDialog({
    dialog,
    component: OrganisationUserPromoteComponent,
    header: "Promote user",
    data: { user }
  });
}

function removeUser(user: User): void {
  openDialog({
    dialog,
    component: OrganisationUserRemoveComponent,
    header: "Remove user",
    data: { user }
  });
}

</script>