<template>
  <Fieldset class="mb-5" legend="Delete Organisation">
    <div class="flex justify-content-center">
        <Button label="!!! DELETE ORGANISATION !!!" severity="danger" @click="remove" />
      </div>
  </Fieldset>
</template>
<script lang="ts" setup>
// PrimeVue
import Button from 'primevue/button';
import Fieldset from 'primevue/fieldset';
import { useDialog } from 'primevue/usedialog';

// Components
import OrganisationDeleteComponent from "./organisation-delete.component.vue";

// Stores
import { useOrganisationStore } from '../stores/organisation.store';

// Utils
import { openDialog } from '../utils';

const dialog = useDialog();

const organisationStore = useOrganisationStore();

function remove() {
  if(!organisationStore.selectedOrganisation) {
    return;
  }

  openDialog({
    dialog,
    component: OrganisationDeleteComponent,
    header: "Delete organisation",
    data: { organisation: organisationStore.selectedOrganisation }
  });

}
</script>