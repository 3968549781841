// Libs
import { RouteLocationNormalizedLoaded } from "vue-router";

export function paramFromRoute(
  route: RouteLocationNormalizedLoaded,
  param: string
): string | undefined {
  const value = route.params[param];
  if (typeof value === "string") {
    return value;
  } else if (value instanceof Array && value.length > 0) {
    return value[0];
  }
}
