// Libs
import { useLocalStorage } from "@vueuse/core";
import { defineStore } from "pinia";

export const useTokenStore = defineStore("token", () => {
  const token = useLocalStorage<string>("token", null);
  function setToken(value: string | null): void {
    token.value = value;
  }
  function clearToken(): void {
    this.setToken(null);
  }
  return { token, setToken, clearToken };
});