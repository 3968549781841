// Libs
import { defineStore } from "pinia";
import { computed } from "vue";
import { useLocalStorage } from "@vueuse/core";

// Stores
import { useSurrealdbConnectionStore } from "./surrealdb-connection.store";
import { useTokenStore } from "./token.store";
import { useOrganisationStore } from "./organisation.store";

// DTO's
import { User, UserRegister } from "../dto/user";

const API_BASE_URL = process.env.API_BASE_URL || "http://localhost:3000";

export const useAuthStore = defineStore("auth", () => {
  const tokenStore = useTokenStore();
  const surrealdbStore = useSurrealdbConnectionStore();
  const organisationStore = useOrganisationStore();

  const _me = useLocalStorage<string>("me", null);
  const me = computed<User | undefined>(() =>
    _me.value ? JSON.parse(_me.value) : undefined
  );
  function setMe(value: User | null): void {
    _me.value = JSON.stringify(value);
  }
  function clearMe(): void {
    setMe(null);
  }

  const isAuth = computed(() => !!me.value);
  const isAdmin = computed(() =>
    organisationStore.selectedOrganisation?.users.some(
      (user) =>
        user.user.id === me.value?.id &&
        (user.role === "admin" || user.role === "owner")
    )
  );
  const isOwner = computed(() =>
    organisationStore.selectedOrganisation?.users.some(
      (user) => user.user.id === me.value?.id && user.role === "owner"
    )
  );

  async function login(email: string, password: string): Promise<void> {
    const token = await surrealdbStore.db.signin({
      namespace: process.env.SURREALDB_NAMESPACE,
      database: process.env.SURREALDB_DATABASE,
      scope: "user",
      email,
      password,
    });

    tokenStore.setToken(token);
    const me = await surrealdbStore.db.info<User>();
    if (me) {
      setMe(me);
    }
  }

  async function register(
    captchaCode: string,
    user: UserRegister
  ): Promise<void> {
    const response = await fetch(`${API_BASE_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-recaptcha-token": captchaCode,
      },
      body: JSON.stringify(user),
    });

    if (response.status !== 200) {
      const data = await response.json();
      throw new Error(data?.message);
    }
  }

  async function initResetPassword(
    captchaCode: string,
    email: string
  ): Promise<void> {
    const response = await fetch(`${API_BASE_URL}/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-recaptcha-token": captchaCode,
      },
      body: JSON.stringify({
        email,
      }),
    });

    if (response.status !== 200) {
      const data = await response.json();
      throw new Error(data?.message);
    }
  }

  async function activateAccount({
    captchaCode,
    code,
    password,
    name,
  }: {
    captchaCode: string;
    code: string;
    password?: string;
    name?: string;
  }) {
    const response = await fetch(`${API_BASE_URL}/activate-account`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-recaptcha-token": captchaCode,
      },
      body: JSON.stringify({
        code,
        ...(password ? { password } : {}),
        ...(name ? { name } : {}),
      }),
    });

    if (response.status !== 200) {
      throw new Error(await response.json());
    }
  }

  async function logout(): Promise<void> {
    await surrealdbStore.db.invalidate();
    tokenStore.clearToken();
    clearMe();
  }

  return {
    isAuth,
    isAdmin,
    isOwner,
    login,
    logout,
    register,
    initResetPassword,
    activateAccount,
    me,
  };
});
