// Libs
import { createApp } from "vue";
import { createPinia } from "pinia";
import PrimeVue from "primevue/config";
import DialogService from "primevue/dialogservice";
import { VueReCaptcha } from "vue-recaptcha-v3";
import Tooltip from "primevue/tooltip";
import ConfirmationService from "primevue/confirmationservice";

// Styles
import "../scss/app.scss";

// Routes
import { router } from "./router";

// Stores
import { useTokenStore } from "./stores/token.store";

// Components
import AppComponent from "./components/app.component.vue";

const pinia = createPinia();
const app = createApp(AppComponent);

app.use(pinia);
app.use(router);
app.use(PrimeVue);
app.use(DialogService);
app.use(ConfirmationService);
app.use(VueReCaptcha, {
  siteKey: process.env.GOOGLE_CAPTCHA_SITE_KEY || "",
  loaderOptions: {},
});

app.directive("tooltip", Tooltip);

const tokenStore = useTokenStore();

(async (): Promise<void> => {
  tokenStore.$onAction(({ name }) => {
    if (name === "clearToken") {
      router.push({ name: "login" });
    }
  });

  app.mount("#app");
})();
