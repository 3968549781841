<template>
  <LayoutComponent>
    <div class="grid">
      <div class="col-12 lg:col-6 lg:col-offset-3 xl:col-4 xl:col-offset-4 gap-2 flex flex-column gap-2">
        <h1>Select Organisation</h1>
        <Button v-for="organisation of organisationStore.organisations" :label="organisation.name" icon="pi pi-arrow-right" icon-pos="right" class="w-full" severity="contrast" outlined @click="selectOrganisation(organisation.id)" />
        <router-link to="/organisation/create" class="w-full">
          <Button label="Create Organisation" icon="pi pi-plus" icon-pos="right" class="w-full" />
        </router-link>
      </div>
    </div>
  </LayoutComponent>
</template>
<script lang="ts" setup>
// Libs
import { useRouter } from "vue-router";

// PrimeVue
import Button from "primevue/button";

// Stores
import { useOrganisationStore } from "../stores/organisation.store"

// Components
import LayoutComponent from './layout.component.vue';

const organisationStore = useOrganisationStore();
const $router = useRouter();


function selectOrganisation(organisationId: string): void {
  organisationStore.setSelectedOrganisation(organisationId);
  $router.replace("/track");
}
</script>