<template>
  <LayoutComponent>
    <div class="grid grid-nogutter">
      <div class="col-12 lg:col-8 lg:col-offset-2 xl:col-6 xl:col-offset-3">
        <h1>Create Organisation</h1>
        <form @submit.prevent="submit(organisationForm)">
          <organisation-form-component
            v-model="organisationForm"
            @isValid="(value) => (isValid = value)"
          />
          <div class="flex justify-content-center">
            <Button type="submit" :disabled="!isValid"> Create Organisation </Button>
          </div>
        </form>
      </div>
    </div>
  </LayoutComponent>
</template>
<script lang="ts" setup>
// Libs
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useReCaptcha } from "vue-recaptcha-v3";

// PrimeVue
import Button from "primevue/button";

// Components
import LayoutComponent from "./layout.component.vue";
import OrganisationFormComponent, {
  OrganisationForm,
} from "./organisation-form.component.vue";

// Stores
import { useOrganisationStore } from "../stores/organisation.store";
import { useAuthStore } from "../stores/auth.store";
import { getRecaptcha } from "../utils";

const $router = useRouter();
const reCaptcha = useReCaptcha();

const organisationStore = useOrganisationStore();
const authStore = useAuthStore();

const saving = ref(false);
const isValid = ref(false);

const organisationForm = ref<OrganisationForm>({
  name: "",
});

async function submit(organisation: OrganisationForm) {
  if (authStore.me === undefined) {
    throw new Error("User is not authenticated");
  }

  saving.value = true;
  const newOrganisation = await organisationStore.create({
    organisation: {
      name: organisation.name,
    },
  });

  await organisationStore.reloadOrganisations();
  organisationStore.setSelectedOrganisation(newOrganisation.id);
  $router.replace("/projects");
}
</script>
