// Libs
import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

// Stores
import { liveRecordsSession, useSurrealdbConnectionStore } from "./surrealdb-connection.store";
import { useAuthStore } from "./auth.store";
import { useTokenStore } from "./token.store";

// DTO's
import {
  Organisation,
  OrganisationCreate,
  OrganisationUpdate,
  OrganisationUserRole,
} from "../dto/organisation";
import { User } from "../dto/user";

const API_BASE_URL = process.env.API_BASE_URL || "http://localhost:3000";

export const useOrganisationStore = defineStore("organisation", () => {
  const surrealdbStore = useSurrealdbConnectionStore();
  const authStore = useAuthStore();
  const tokenStore = useTokenStore();

  const $router = useRouter();

  const selectedOrganisationInStorage = useLocalStorage<string | undefined>(
    "selectedOrganisation",
    undefined
  );
  const selectedOrganisation = computed<Organisation<User> | undefined>(() => {
    return (
      organisations.value.find(
        (organisation) => organisation.id === selectedOrganisationInStorage.value
      ) || undefined
    );
  });

  if (!authStore.isAuth) {
    selectedOrganisationInStorage.value = undefined;
  }

  let isOrganisationsLoaded = false;
  const organisations = ref<Organisation<User>[]>([]);

  let liveQuery: liveRecordsSession | undefined;
  watch(
    () => authStore.me,
    () => setLiveQuery()
  );

  async function setLiveQuery() {
    if (liveQuery) {
      liveQuery.end();
    }

    liveQuery = await surrealdbStore.liveRecords<Organisation<User>>(
      `
      SELECT
        *,
        (
          SELECT
            in.* as user,
            role,
            active
          FROM <-memberOf.*
        ) as users
      FROM organisation
`,
      organisations
    );
  }

  (async () => {
    await surrealdbStore.waitForConnection();
    await setLiveQuery();
    isOrganisationsLoaded = true;
  })();

  async function waitForOrganisationsToBeloaded(): Promise<void> {
    while (!isOrganisationsLoaded) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  }

  async function get(organisationId: string): Promise<Organisation<User>> {
    const organisation = (
      await surrealdbStore.db.query<Organisation<User>[][]>(
        `
      SELECT
        *,
        (
          SELECT
            in.* as user,
            role,
            active
          FROM <-memberOf.*
        ) as users
      FROM organisation
      WHERE id = $organisationId
    `,
        {
          organisationId,
        }
      )
    )[0][0];

    if (!organisation) {
      throw new Error("Organisation not found");
    }

    return organisation;
  }

  async function reloadOrganisations(): Promise<void> {
    await setLiveQuery();
  }

  function setSelectedOrganisation(organisationId: string): void {
    selectedOrganisationInStorage.value = organisationId;
  }

  function clearSelectedOrganisation(): void {
    selectedOrganisationInStorage.value = undefined;
    $router.push("/select-organisation");
  }

  async function create({
    organisation,
  }: {
    organisation: OrganisationCreate;
  }): Promise<Organisation<User>> {
    const result = await surrealdbStore.db.query<{ id: string }[]>(`
      BEGIN TRANSACTION;
        LET $organisation = (CREATE organisation CONTENT ${JSON.stringify(organisation)})[0];
        RELATE $auth->memberOf->$organisation SET role = "owner", active = true;
        RETURN $organisation;
      COMMIT TRANSACTION;
    `);
    if (result.length === 0) {
      throw new Error("Failed to create organisation");
    }

    const organisationId = result[0].id;

    return get(organisationId);
  }

  async function update(
    organisationId: string,
    organisation: OrganisationUpdate
  ): Promise<Organisation<User>> {
    await surrealdbStore.db.merge<Organisation, OrganisationUpdate>(organisationId, organisation);

    return get(organisationId);
  }

  async function updateUser(
    organisationId: string,
    userId: string,
    role: OrganisationUserRole
  ): Promise<Organisation<User>> {
    await surrealdbStore.db.query(
      `
      UPDATE memberOf
      SET role = $role
      WHERE
        out = $organisationId AND
        in = $userId
    `,
      {
        organisationId,
        userId,
        role,
      }
    );

    return get(organisationId);
  }

  async function removeUser(
    organisationId: string,
    userId: string
  ): Promise<Organisation<User>> {
    await surrealdbStore.db.query(
      `
      DELETE FROM memberOf
      WHERE
        out = $organisationId AND
        in = $userId
    `,
      {
        organisationId,
        userId,
      }
    );

    return get(organisationId);
  }

  async function invite({
    organisationId,
    captcha,
    email,
    role,
  }: {
    organisationId: string;
    captcha: string;
    email: string;
    role: "user" | "admin";
  }): Promise<{ organisation: Organisation<User>; userId: string }> {
    const response = await fetch(
      `${API_BASE_URL}/organisation/${organisationId}/invite`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-recaptcha-token": captcha,
          "x-auth-token": tokenStore.token,
        },
        body: JSON.stringify({
          user: {
            email,
            role,
          },
        }),
      }
    );

    const data = await response.json();
    if (response.status !== 200) {
      throw new Error(data?.message);
    }

    return {
      organisation: await get(organisationId),
      userId: data.user.id,
    };
  }

  async function transferOwnership({
    captcha,
    organisationId,
    userId,
  }: {
    captcha: string;
    organisationId: string;
    userId: string;
  }): Promise<Organisation<User>> {
    const response = await fetch(
      `${API_BASE_URL}/organisation/${organisationId}/user/${userId}/transfer-ownership`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-recaptcha-token": captcha,
          "x-auth-token": tokenStore.token,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error((await response.json()).message);
    }

    return get(organisationId);
  }

  async function removeOrganisation(organisationId: string) {
    await surrealdbStore.db.query(`
      BEGIN TRANSACTION;
        DELETE $organisationId;
        DELETE FROM memberOf WHERE out = $organisationId;
      COMMIT TRANSACTION;
    `, {
      organisationId
    });
  }

  watch(
    () => authStore.isAuth,
    () => {
      selectedOrganisationInStorage.value = undefined;
    }
  );

  return {
    organisations,
    reloadOrganisations,
    waitForOrganisationsToBeloaded,
    get,
    create,
    update,
    updateUser,
    removeUser,
    invite,
    transferOwnership,
    removeOrganisation,
    selectedOrganisation,
    setSelectedOrganisation,
    clearSelectedOrganisation,
  };
});
