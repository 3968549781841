<template>
  <confirm-component @submit="submit" @cancel="cancel" v-model:loading="loading">
    Are you sure you want to transfer ownership to user {{ user.email }}?
  </confirm-component>
</template>
<script lang="ts" setup>
// Libs
import { inject, ref } from 'vue';
import { useReCaptcha } from 'vue-recaptcha-v3';

// Components
import confirmComponent from './confirm.component.vue';

// DTO
import { User } from '../dto/user';

// Stores
import { useOrganisationStore } from '../stores/organisation.store';
import { getRecaptcha } from '../utils';

const dialogRef: any = inject('dialogRef');

const reCaptcha = useReCaptcha();

const organisationStore = useOrganisationStore();

const user: User = dialogRef.value.data.user;

const loading = ref(false);

async function submit() {
  if(!organisationStore.selectedOrganisation) {
    return;
  }

  loading.value = true;

  // Load captcha
  const captchaCode = await getRecaptcha(reCaptcha!);

  await organisationStore.transferOwnership({
    captcha: captchaCode,
    organisationId: organisationStore.selectedOrganisation.id,
    userId: user.id,
  });

  organisationStore.reloadOrganisations();

  close();
}

function cancel() {
  close();
}

function close() {
  dialogRef.value.close();
}
</script>